import React from "react";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { graphql } from "gatsby";

import Layout from "../components/containers/layout";
import Heading from "../components/typography/heading";
import Container from "../components/containers/container";
import Section from "../components/containers/section";
import SEO from "../components/globals/seo";
import HTMLRenderer from "../utils/htmlRenderer";

const TermsWrap = styled(Container)`
  
`;

const TermsPage = ({ data }) => {
  const { title, content } = data.prismic.data;

  return (
    <Layout>
      <SEO
        title="Terms"
        description="The Terms of TopTekkers"
        path="/terms"
      />

      <Section>
        <TermsWrap>
          <Heading>{title.text}</Heading>
          <HTMLRenderer html={content.html} />
        </TermsWrap>
      </Section>
    </Layout>
  );
};

export const query = graphql`
{
  prismic: prismicTerms {
    data {
      title {
        text
      }
      content {
        html
      }
    }
  }
}
`;

TermsPage.propTypes = {
  data: PropTypes.object.isRequired,
};


export default withTheme(TermsPage);
